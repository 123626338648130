import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableAsyncSelect from 'react-select/async-creatable';

function Registration() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    member_id: '',
    nnf_id: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    email: '',
    phone: '',
    whatsapp: '',
    package: '',
    workshop: false,
    dob: ''
  });

  const [packages, setPackages] = useState([
    { label: 'Consultant', value: '' },
    { label: 'PG Student', value: 4000 },
    { label: 'Nurse', value: 3000 },
  ]);

  const [memberOptions, setMemberOptions] = useState([]);
  const [nnfOptions, setNnfOptions] = useState([]);

  const [membershipStatus, setMembershipStatus] = useState({
    iap: { verified: false, loading: true },
    nnf: { verified: false, loading: true }
  });

  const [whatsappSameAsPhone, setWhatsappSameAsPhone] = useState(false);

  const [registrationAmount, setRegistrationAmount] = useState(0);

  const [accompanyingPersons, setAccompanyingPersons] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
        ...(name === 'phone' && whatsappSameAsPhone ? { whatsapp: value } : {})
      }));
    }
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?phone=${formData.phone}`);
        const data = await response.json();
        
        if (data && data.length > 0) {
          setFormData(prevState => ({
            ...prevState,
            ...data[0]
          }));
        }
        setStep(3);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setStep(3);
      }
    }
  };

  const handlePackageSelect = (e) => {
    handleChange({
      target: {
        name: 'package',
        value: e.target.value
      }
    });
  };

  const handlePackageSubmit = (e) => {
    e.preventDefault();
    if (formData.package) {
      if (formData.package === 'Consultant') {
        setStep(2);
      } else {
        setStep(3);
      }
    }
  };

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const calculateRegistrationAmount = () => {
    let baseAmount = 0;
    if (formData.package === 'Consultant') {
      const age = formData.dob ? calculateAge(formData.dob) : 0;
      if (age >= 70) {
        baseAmount = 0;
      } else if (formData.member_id && formData.nnf_id) {
        baseAmount = 5000;
      } else if (formData.member_id || formData.nnf_id) {
        baseAmount = 6000;
      } else {
        baseAmount = 9000;
      }
    } else if (formData.package === 'PG Student') {
      baseAmount = 4000;
    } else if (formData.package === 'Nurse') {
      baseAmount = 3000;
    }

    if (formData.workshop && formData.package !== 'Nurse') {
      baseAmount += 750;
    }

    return baseAmount + (accompanyingPersons.length * 6000);
  };

  const calculateTotalWithGST = (baseAmount) => {
    const gst = baseAmount * 0.18;
    return baseAmount + gst;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 3) {
      const amount = calculateRegistrationAmount();
      setRegistrationAmount(amount);
      setStep(4);
    }
  };

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true);
    try {
      const registrationData = {
        ...formData,
        accompanyingPersons: accompanyingPersons.map(p => ({ name: p.name })),
        amount: calculateRegistrationAmount(),
        total_amount: calculateTotalWithGST(calculateRegistrationAmount())
      };

      const response = await fetch('https://zinvos.tessst.com/api/calneo/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData)
      });

      const data = await response.json();

      if (data.status === 'success') {
        console.log('Registration successful:', data);
        
        if (data.payment_url) {
          window.open(data.payment_url, "_blank");
        }
        else if (calculateRegistrationAmount() === 0) {
          window.location.href = '/registration-success';
        } else {
          throw new Error('Payment URL not received from server');
        }
      } else {
        throw new Error(data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      alert('Registration failed: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const loadMemberOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) return [];
    
    try {
      const response = await fetch(`https://zinvos.tessst.com/api/calneo/?member_id=${inputValue}`);
      const data = await response.json();
      
      const options = data.map(item => ({
        value: item.member_id,
        label: `${item.name} (${item.member_id})`
      }));

      return options;
    } catch (error) {
      console.error('Error loading members:', error);
      return [];
    }
  };

  const loadNNFOptions = async (inputValue) => {
    if (!inputValue || inputValue.length < 2) return [];

    try {
      const response = await fetch(`https://zinvos.tessst.com/api/calneo/?nnf_id=${inputValue}`);
      const data = await response.json();
      
      const options = data.map(item => ({
        value: item.nnf_id,
        label: `${item.name} (${item.nnf_id})`
      }));

      return options;
    } catch (error) {
      console.error('Error loading NNF options:', error);
      return [];
    }
  };

  const verifyInitialMemberships = async () => {
    setMembershipStatus({
      iap: { verified: false, loading: true },
      nnf: { verified: false, loading: true }
    });

    if (formData.member_id) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?member_id=${formData.member_id}`);
        const data = await response.json();
        const matchingMember = data.find(m => m.member_id === formData.member_id);
        if (matchingMember) {
          const option = {
            value: matchingMember.member_id,
            label: `${matchingMember.member_id} - ${matchingMember.name}`
          };
          setMemberOptions([option]);
          setFormData(prev => ({
            ...prev,
            member_id: matchingMember.member_id
          }));
          setMembershipStatus(prev => ({
            ...prev,
            iap: { verified: true, loading: false }
          }));
        } else {
          setMembershipStatus(prev => ({
            ...prev,
            iap: { verified: false, loading: false }
          }));
        }
      } catch (error) {
        console.error('Error verifying IAP membership:', error);
        setMembershipStatus(prev => ({
          ...prev,
          iap: { verified: false, loading: false }
        }));
      }
    } else {
      setMembershipStatus(prev => ({
        ...prev,
        iap: { verified: false, loading: false }
      }));
    }
    
    if (formData.nnf_id) {
      try {
        const response = await fetch(`https://zinvos.tessst.com/api/calneo/?nnf_id=${formData.nnf_id}`);
        const data = await response.json();
        const matchingMember = data.find(m => m.nnf_id === formData.nnf_id);
        if (matchingMember) {
          const option = {
            value: matchingMember.nnf_id,
            label: `${matchingMember.nnf_id} - ${matchingMember.name}`
          };
          setNnfOptions([option]);
          setFormData(prev => ({
            ...prev,
            nnf_id: matchingMember.nnf_id
          }));
          setMembershipStatus(prev => ({
            ...prev,
            nnf: { verified: true, loading: false }
          }));
        } else {
          setMembershipStatus(prev => ({
            ...prev,
            nnf: { verified: false, loading: false }
          }));
        }
      } catch (error) {
        console.error('Error verifying NNF membership:', error);
        setMembershipStatus(prev => ({
          ...prev,
          nnf: { verified: false, loading: false }
        }));
      }
    } else {
      setMembershipStatus(prev => ({
        ...prev,
        nnf: { verified: false, loading: false }
      }));
    }
  };

  const handleWhatsappCheckbox = (e) => {
    setWhatsappSameAsPhone(e.target.checked);
    if (e.target.checked) {
      setFormData(prev => ({
        ...prev,
        whatsapp: formData.phone
      }));
    }
  };

  useEffect(() => {
    if (formData.member_id || formData.nnf_id) {
      verifyInitialMemberships();
    }
  }, [formData.member_id, formData.nnf_id]);

  return (
    <div className="registration-container">
      <h2 className="section-title">Conference Registration</h2>
      
      {step === 1 ? (
        <div className="registration-form">
          <h3 className="section-subtitle">Register As</h3>
          <div className="package-buttons">
            {packages.map((pkg, index) => (
              <button
                key={index}
                type="button"
                className={`package-button ${formData.package === pkg.label ? 'selected' : ''}`}
                onClick={() => {
                  handlePackageSelect({ target: { value: pkg.label } });
                  if (pkg.label === 'Consultant') {
                    setStep(2);
                  } else {
                    setStep(3);
                  }
                }}
              >
                <span className="package-label">{pkg.label}</span>
              </button>
            ))}
          </div>
        </div>
      ) : step === 2 && formData.package === 'Consultant' ? (
        <form onSubmit={handlePhoneSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="phone">Phone Number Registered with IAP / NNF If Registered*</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter phone number..."
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Next
          </button>
        </form>
      ) : step === 3 ? (
        <form onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <label htmlFor="name">Full Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          {formData.package === 'Consultant' && (
            <>
              <div className="form-group">
                <label htmlFor="member_id">IAP Membership Number</label>
                {formData.member_id && membershipStatus.iap.verified ? (
                  <input
                    type="text"
                    id="member_id"
                    name="member_id"
                    value={formData.member_id}
                    disabled
                  />
                ) : (
                  <CreatableAsyncSelect
                    id="member_id"
                    name="member_id"
                    value={formData.member_id ? { value: formData.member_id, label: formData.member_id } : null}
                    loadOptions={loadMemberOptions}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: 'member_id',
                          value: selected ? selected.value : ''
                        }
                      });
                      if (selected && !selected.__isNew__) {
                        verifyInitialMemberships();
                      }
                    }}
                    onCreateOption={(inputValue) => {
                      handleChange({
                        target: {
                          name: 'member_id',
                          value: inputValue
                        }
                      });
                      setMembershipStatus(prev => ({
                        ...prev,
                        iap: { verified: false, loading: false }
                      }));
                    }}
                    isClearable
                    placeholder="Type to search or create new member ID..."
                    noOptionsMessage={({ inputValue }) => 
                      inputValue.length < 2 ? "Type at least 2 characters..." : "No results found"
                    }
                    formatCreateLabel={(inputValue) => `Create new ID: "${inputValue}"`}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="nnf_id">NNF Membership Number</label>
                {formData.nnf_id && membershipStatus.nnf.verified ? (
                  <input
                    type="text"
                    id="nnf_id"
                    name="nnf_id"
                    value={formData.nnf_id}
                    disabled
                  />
                ) : (
                  <CreatableAsyncSelect
                    id="nnf_id"
                    name="nnf_id"
                    value={formData.nnf_id ? { value: formData.nnf_id, label: formData.nnf_id } : null}
                    loadOptions={loadNNFOptions}
                    onChange={(selected) => {
                      handleChange({
                        target: {
                          name: 'nnf_id',
                          value: selected ? selected.value : ''
                        }
                      });
                      if (selected && !selected.__isNew__) {
                        verifyInitialMemberships();
                      }
                    }}
                    onCreateOption={(inputValue) => {
                      handleChange({
                        target: {
                          name: 'nnf_id',
                          value: inputValue
                        }
                      });
                      setMembershipStatus(prev => ({
                        ...prev,
                        nnf: { verified: false, loading: false }
                      }));
                    }}
                    isClearable
                    placeholder="Type to search or create new NNF ID..."
                    noOptionsMessage={({ inputValue }) => 
                      inputValue.length < 2 ? "Type at least 2 characters..." : "No results found"
                    }
                    formatCreateLabel={(inputValue) => `Create new ID: "${inputValue}"`}
                  />
                )}
              </div>
              <div className="form-group">
                <label htmlFor="dob">Date of Birth *</label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                  max={new Date().toISOString().split('T')[0]}
                />
              </div>
            </>
          )}

          <div className="form-group">
            <label htmlFor="address">Address *</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="city">City *</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="state">State *</label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="country">Country *</label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="pincode">Pincode *</label>
              <input
                type="text"
                id="pincode"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="whatsapp">WhatsApp Number</label>
            <input
              type="tel"
              id="whatsapp"
              name="whatsapp"
              value={formData.whatsapp}
              onChange={handleChange}
              disabled={whatsappSameAsPhone}
            />
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="whatsappSameAsPhone"
                checked={whatsappSameAsPhone}
                onChange={handleWhatsappCheckbox}
              />
              <label htmlFor="whatsappSameAsPhone">Same as phone number</label>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="workshop"
                name="workshop"
                checked={formData.workshop}
                onChange={(e) => handleChange({
                  target: {
                    name: 'workshop',
                    value: e.target.checked
                  }
                })}
              />
              <label htmlFor="workshop">
                Register for Workshop {formData.package === 'Nurse' ? '(Free)' : '(₹750)'}
              </label>
            </div>
          </div>

          <button type="submit" className="submit-button">
            Complete Registration
          </button>
        </form>
      ) : (
        <div className="confirmation-screen">
          <h3>Registration Summary</h3>
          <div className="summary-details">
            <div className="summary-row">
              <span>Name:</span>
              <span>{formData.name}</span>
            </div>
            <div className="summary-row">
              <span>Package:</span>
              <span>{formData.package}</span>
            </div>
            {formData.package === 'Consultant' && (
              <>
                <div className="summary-row">
                  <span>IAP Member:</span>
                  <span>{formData.member_id ? 'Yes' : 'No'}</span>
                </div>
                <div className="summary-row">
                  <span>NNF Member:</span>
                  <span>{formData.nnf_id ? 'Yes' : 'No'}</span>
                </div>
              </>
            )}
            <div className="summary-row">
              <span>Email:</span>
              <span>{formData.email}</span>
            </div>
            <div className="summary-row">
              <span>Phone:</span>
              <span>{formData.phone}</span>
            </div>
            <div className="summary-row">
              <span>Workshop:</span>
              <span>{formData.workshop ? 'Yes' : 'No'}</span>
            </div>
            <div className="accompanying-section">
              <h4 className="accompanying-title">Accompanying Persons</h4>
              <div className="accompanying-list">
                {accompanyingPersons.map((person, index) => (
                  <div key={index} className="accompanying-item">
                    <div className="accompanying-input-group">
                      <span className="accompanying-number">{index + 1}</span>
                      <input
                        type="text"
                        value={person.name}
                        onChange={(e) => {
                          const newPersons = [...accompanyingPersons];
                          newPersons[index].name = e.target.value;
                          setAccompanyingPersons(newPersons);
                        }}
                        placeholder="Enter full name"
                        className="accompanying-input"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setAccompanyingPersons(accompanyingPersons.filter((_, i) => i !== index));
                        }}
                        className="accompanying-remove-btn"
                        aria-label="Remove accompanying person"
                      >
                        ×
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => {
                    setAccompanyingPersons([...accompanyingPersons, { name: '' }]);
                  }}
                  className="accompanying-add-btn"
                >
                  <span className="accompanying-add-icon">+</span>
                  Add Accompanying Person
                  <span className="accompanying-price">(+₹6000)</span>
                </button>
              </div>

              {(accompanyingPersons.length > 0 || formData.workshop) && (
                <div className="amount-breakdown">
                  <div className="breakdown-row">
                    <span>Base Registration:</span>
                    <span className="breakdown-amount">
                      ₹{calculateRegistrationAmount() - (accompanyingPersons.length * 6000) - (formData.workshop && formData.package !== 'Nurse' ? 750 : 0)}
                    </span>
                  </div>
                  {formData.workshop && formData.package !== 'Nurse' && (
                    <div className="breakdown-row">
                      <span>Workshop Fee:</span>
                      <span className="breakdown-amount">₹750</span>
                    </div>
                  )}
                  {accompanyingPersons.length > 0 && (
                    <div className="breakdown-row">
                      <span>Accompanying Persons ({accompanyingPersons.length}):</span>
                      <span className="breakdown-amount">₹{accompanyingPersons.length * 6000}</span>
                    </div>
                  )}
                  <div className="breakdown-row">
                    <span>Subtotal:</span>
                    <span className="breakdown-amount">₹{calculateRegistrationAmount()}</span>
                  </div>
                  <div className="breakdown-row">
                    <span>GST (18%):</span>
                    <span className="breakdown-amount">₹{(calculateRegistrationAmount() * 0.18).toFixed(2)}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="summary-row total-amount">
              <span>Total Amount (inc. GST):</span>
              <span>₹{calculateTotalWithGST(calculateRegistrationAmount()).toFixed(2)}</span>
            </div>
          </div>
          
          <div className="button-group">
            <button 
              type="button" 
              className="back-button"
              onClick={() => setStep(3)}
            >
              <span>Edit Details</span>
            </button>
            <button 
              type="button" 
              className="payment-button"
              onClick={handlePaymentSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className="loading-spinner">
                  <div className="spinner"></div>
                  <span>Processing...</span>
                </div>
              ) : (
                <>
                  <span>{calculateRegistrationAmount() === 0 ? 'Register' : 'Pay Now'}</span>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Registration; 